const projectsData = [
  {
    title: "Web Documentary",
    description:
      "School project to welcome new-comers at the academy with a focus point on inclusion. Even thought it's quite a simple webpage, I am quite proud of this one for its design and important message.",
    skills: ["HTML", "CSS", "JavaScript"],
    image: require("../img/project1.png"),
    website: "https://project2.portfolio.frenchindenmark.dk/",
  },
  {
    title: "Provide Business",
    description:
      "Internship project. I got assigned with the design and asked to implement it for the new website. It has been a good exercise for responsiveness and SEO.",
    skills: ["HTML", "CSS", "JavaScript", "SEO", "React"],
    image: require("../img/project3.png"),
    website: "https://danskmødebooking.dk/",
  },
  {
    title: "Weather Application",
    description:
      "Small project made with Angular and TypeScript. The aim here was to get more familiar with those two languages. This is the first I've developed using Angular.",
    skills: ["HTML", "CSS", "Typescript", "Angular"],
    image: require("../img/project4.png"),
    website: "https://angularproject.frenchindenmark.dk/",
  },  
];
export default projectsData;
